import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ChangePasswordPayload, CreateUserPayload, StorageKeys, User } from '../../models/user.model'
import { Collection } from '../../models/collection.model'


const PAGE = 1
const PER_PAGE = 40

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  setRemember(remember: boolean) {
    localStorage.setItem(StorageKeys.Remember, String(remember))
  }

  getRemember(): boolean {
    const value = localStorage.getItem(StorageKeys.Remember)
    return value == 'true'
  }

  setCurrentUser(u: User) {
    const data = JSON.stringify(u)

    if (this.getRemember()) {
      localStorage.setItem(StorageKeys.CurrentUser, data)
    } else {
      sessionStorage.setItem(StorageKeys.CurrentUser, data)
    }
  }

  getCurrentUser(): User {
    let data = localStorage.getItem(StorageKeys.CurrentUser)
    if (!data) {
      data = sessionStorage.getItem(StorageKeys.CurrentUser)
    }

    if (data) {
      return new User(JSON.parse(data))
    }

    return new User()
  }

  async getUsers(
    page: number,
    perPage: number,
    searchTerms?: string,
    status?: string,
    roles?: string[],
    orderBy?: string,
    isDesc?: boolean,
  ): Promise<Collection<User>> {
    if (!page || !perPage) {
      page = PAGE
      perPage = PER_PAGE
    }

    let queryParams = `page=${page}&perPage=${perPage}`

    if (searchTerms) {
      queryParams += `&searchTerms=${searchTerms}`
    }

    if (status) {
      queryParams += `&status=${status}`
    }
   
    roles?.forEach(role => {
      queryParams += `&roles=${role}`
    })

    if (orderBy) {
      queryParams += `&orderBy=${orderBy}`
      if (isDesc) {
        queryParams += `&isDesc=true`
      }
    }

    const r = await this.http.get(`/api/bo/users?${queryParams}`).toPromise()
    return new Collection(r, User)
  }


  async getAllUsers(): Promise<Collection<User>> {
    return this.getUsers(1, 1000, '', '', [], '', false)
  }

  async getUserById(userId: number): Promise<User> {
    const r = await this.http.get(`/api/bo/users/${userId}`).toPromise()
    return new User(r)
  }

  async createUser(u: CreateUserPayload): Promise<User> {
    const r = await this.http.post(`/api/bo/users`, u).toPromise()
    return new User(r)
  }

  async updateUser(u: any): Promise<User> {
    const payload = {
      name: u.name,
      status: u.status,
      role: u.role,
    }
    const r = await this.http.patch(`/api/bo/users/${u.id}`, payload).toPromise()
    return new User(r)
  }

  async updatePassword(userId: number, payload: ChangePasswordPayload) {
    return this.http.put(`/api/bo/users/${userId}/password`, payload).toPromise()
  }

  uploadProfileImage(userID: number, file: File, size: any, type?: any) {
    const formData = new FormData()
    formData.append('image', file)
    if (type) {
      formData.append('type', type)
    }

    return this.http.post(`/api/bo/users/${userID}/image`, formData)
  }

  async deleteUser(userId: number ) {
    await this.http.delete(`/api/bo/users/${userId}`).toPromise()
  }

  async getSplahAdsUnRead(){
    return this.http.get('/api/splash-ads/unread?perPage=20&page=1').toPromise()
  }

  async readSplashAds(splashId: number){
    return this.http.post(`/api/splash-ads/${splashId}/read`, {}).toPromise()
  }
}
